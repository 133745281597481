<template>
  <div class="main">
    <div ref="header" class="header">
      <div class="headerBox">
        <div class="headerLeft">
          <img class="logo" src="@/assets/image/logo_new.png" alt="">
          <div class="menu">
            <div class="item">
              <div @click="goIndex">首页</div>
            </div>
            <div class="item">
              <div class="name">关于我们</div>
            </div>

          </div>
        </div>
        <div class="mobile">
          <span>
            免费使用开通电话：18910931905
          </span>
          <a href="https://open.work.weixin.qq.com" target="_blank"><img src="https://open.work.weixin.qq.com/service/img?id=wwb6bb99fb4251fe03&t=isp&c=white&s=medium" srcset="https://open.work.weixin.qq.com/service/img?id=wwb6bb99fb4251fe03&t=isp&c=white&s=medium@2x 2x" referrerpolicy="unsafe-url" alt="企业微信"></a>
        </div>
      </div>
    </div>
    <div class="card-box">
      <div class="img-style" v-html="description"> </div>
    </div>
    <!-- 底部 -->
    <div class="footer">
      <p class="infos">
        <img src="https://newoss.zhulong.com/edu/202012/08/32/160232ahm9yclsgkogy1tc.png" alt="">
        京ICP备2022034276号-2 北京金来客科技有限公司 通讯地址：北京市石景山区实兴大街30号院3号楼2层D-2012
      </p>
    </div>
  </div>
</template>
<script>
import { getButtom } from '@/api/public.js'
export default {
  components: {
  },
  data() {
    return {
      menu_id: 28,
      imgList: [],
      description: '',
      myTitle: ''
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    goIndex() {
      this.$router.push({
        path: '/'
      })
    },
    getData() {
      console.log(this.menu_id, 'this.menu_id')
      const info = {
        menu_id: this.menu_id
      }
      getButtom(info).then(res => {
        console.log(res, 'res')
        if (res.result !== null) {
          this.imgList = res.result.info
          this.description = res.result.description
          this.myTitle = res.result.name
          this.seo()
        } else {
          this.imgList = null
        }
      }).then(() => {
        var btnArr = document.getElementsByClassName('imgbtn')
        console.log(document.getElementsByClassName('imgbtn'), 'btnArr')
        setTimeout(() => {
          for (let i = 0; i < btnArr.length; i++) {
            btnArr[i].addEventListener('click', function() {
              // console.log('咨询')
              document.getElementsByClassName('chatBtn')[0].click()
            })
          }
        }, 4000)
        // const contentList = document.getElementsByClassName('img-style')[0].children
        // console.log(contentList, 'contentList')
        // setTimeout(() => {
        //   for (let i = 0; i < contentList.length; i++) {
        //     if (contentList[i].innerHTML.includes('/script')) {
        //       const scriptSrc = contentList[i].childNodes[1].childNodes[0].src
        //       const scriptTag = document.createElement('script')
        //       scriptTag.src = scriptSrc
        //       contentList[i].childNodes[1].childNodes[0].appendChild(scriptTag)
        //       console.log(contentList[i].childNodes[1], 'contentList[i].childNodes[1].childNodes[0]')
        //     }
        //   }
        // }, 5000)
      })
    },
    seo() {
      const my_title = document.getElementsByTagName('title')[0]
      my_title.innerHTML = this.myTitle
      // 设置关键词
      // const my_keys = document.getElementsByName('keywords')[0]
      // my_keys.content = '筑龙网,筑龙学社,中国建筑网,建筑教育,注册考试,技能培训,土木工程,建筑工程'
      // 设置描述
      // const my_description = document.getElementsByName('description')[0]
      // my_description.content = '筑龙学社|筑龙网-建筑教育行业引领者，面向设计师,建造师,工程师,造价师,bim等建设全专业人群，提供注考取证及技能培训产品,建筑图纸及资料文档免费下载交流服务'
    }
  }
}

</script>
<style scoped lang="less">
.menu{
  height: 40px;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  .item{
    margin-left: 60px;
    position: relative;
    cursor: pointer;
    .name::after{
      content: "";
      position: absolute;
      bottom: 2px;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: #395ddc;
    }
  }
}
.header{
  width: 100%;
  height: 70px;
  background: #FFFFFF;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 899;
  .headerBox{
    width: 1200px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .headerLeft{
      display: flex;
      align-items: center;
      .aboutUs{
        cursor: pointer;
        margin-left: 20px;
      }
    }
    .logo{
      height: 50px;
    }
    .mobile{
      height: 28px;
      display: flex;
      align-items: center;
      span{
        font-family: PingFangSC-Medium;
        font-size: 20px;
        color: #333333;
        letter-spacing: 0;
        font-weight: 600;
        margin: 2px 8px 0 10px;
      }
    }
  }
}
.card-box{
  padding-top: 70px;
}
.img-style{
  width: 1200px;
  background-color: #FFF;
  margin: auto;
  padding: 10px 0 0 0;
  /deep/img{
    display: block;
    margin: 0 auto !important;
    // width: 1140px;
    max-width: 100%;
  }
}
.introdu_img{
  margin: 0 auto;
  width: 1100px;
}

.footer{
  min-height: 90px;
  // background: url('../../assets/image/footer.png') no-repeat;
  // background-position: center center;
  // // background-size: cover;
  // background-position: 50%;
  background-color: #F7F7F7;
  margin:0 auto;
  .infos {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 35px;
    width: 100%;
    height: 22px;
    line-height: 22px;
    font-family: PingFangSC-Medium;
    text-align: center;
    color: #8F8F8F;
    font-size: 16px;
    font-weight: 500;
    img {
      margin-right: 3px;
      width: 22px;
      height: 22px;
      display: block;
    }
  }
}
</style>
