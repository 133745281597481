import request from '@/utils/bbs_request'
// 公共头部、底部
export function getPublic(params) {
  return request({
    url: 'index/index/getPcCommon',
    method: 'get',
    params: params
  })
}

export function getButtom(params) {
  return request({
    url: 'index/index/getButtomMenuDes',
    method: 'get',
    params: params
  })
}
